<template>
  <el-dialog title="分解" :close-on-click-modal="false" :visible.sync="visible" width="45%" @closed="$emit('closeX')">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="100px">
      <el-form-item label="指标名称">
        <label key="dataForm.pName">{{ dataForm.pName }}</label>
      </el-form-item>
      <el-form-item label="指标接收人" prop="userName">
        <label key="dataForm.userName">{{ dataForm.userName }}</label>
      </el-form-item>
      <el-form-item label="指标年份" prop="okrYear">
        <label key="dataForm.okrYear">{{ dataForm.okrYear }}</label>
      </el-form-item>
      <el-form-item label="已分解月份">
        <label key="dataForm.decomposedMonth">{{ dataForm.decomposedMonth }}</label>
      </el-form-item>
      <el-form-item label="月份" prop="months">
        <el-select v-model="dataForm.months" placeholder="请选择" filterable clearable multiple
        @change="doSelectChange(monthList)">
          <el-checkbox :style="{ paddingLeft: '20px' }" :indeterminate="checkOptions.org.isIndeterminate"
            v-model="checkOptions.org.checkAll"
            @change="handleCheckAllChange(monthList, $event)">
            全选
          </el-checkbox>
          <el-option v-for="item in monthList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="新增用户" prop="addUserNumber">
        <el-input-number v-model="dataForm.addUserNumber" :min="0" placeholder="请输入新增用户数"></el-input-number>
      </el-form-item>
      <el-form-item label="增值收入" prop="valueAddedIncome">
        <el-input-number v-model="dataForm.valueAddedIncome" :min="0" placeholder="请输入增值收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="大众业务收入" prop="publicBusinessIncome">
        <el-input-number v-model="dataForm.publicBusinessIncome" :min="0" placeholder="请输入大众业务收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="5G用户发展用户" prop="developmentUser">
        <el-input-number v-model="dataForm.developmentUser" :min="0" placeholder="请输入5G用户发展用户数"></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
</el-dialog>
</template>
  
<script>


import { getByIds } from '@/api/dataflow/olxpokrmanagemain.js'
import { saveOrUpdate } from '@/api/dataflow/olxpokrmanagedetail.js'
import { childCode } from '@/api/sys/region.js'
const cityOptions = [];
export default {
  data() {
    return {
      visible: false,
      isOnSubmit: false,
      checkAll: false,
      checkedCities: [],
      monthList: [],
      cities: cityOptions,
      isIndeterminate: true,
      dataForm: {
        id: null,
        // pId: '',
        pName: '',
        userName: '',
        areaType: '',
        areaCode: '',
        okrState: '',
        reciveDate: '',
        resolveDate: '',
        execDate: '',
        addUserNumber: '',
        resolveMonth: '',
        valueAddedIncome: '',
        publicBusinessIncome: '',
        developmentUser: '',
        decomposedMonth: '',
        okrYear: '',
        months: []
      },
      dataRule: {
        months: [
          { required: true, message: '分解月份不能为空', trigger: 'blur' }
        ],
      },
      //checked使用的数据
      checkOptions: {
        org: {
          isIndeterminate: false,
          checkAll: false,
        },
      }

    }
  },
  computed: {
    userId: {
      get() {
        const uID = this.$store.state.user.id;
        if (uID !== undefined) {
          return String(uID);
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    init(id, okrYear, areaCode) {
      this.dataForm.id = id
      this.visible = true
      this.isOnSubmit = false
      this.dataForm.okrState = '03'
      this.dataForm.okrYear = okrYear
      this.dataForm.areaCode = areaCode
      this.selectMonthList()
      //获取登录时的code
      const code = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
      this.childCodeName(code)
      this.dataForm.userName = this.$cookie.get("Username");
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          getByIds(id).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.pId = data.data.pId
              this.dataForm.pName = data.data.orkName
              this.dataForm.userName = data.data.userName
              this.dataForm.resolveMonth = data.data.resolveMonth
              this.dataForm.decomposedMonth = data.data.decomposedMonth
              this.dataForm.addUserNumber = data.data.addUserNumber
              this.dataForm.valueAddedIncome = data.data.valueAddedIncome
              this.dataForm.publicBusinessIncome = data.data.publicBusinessIncome
              this.dataForm.developmentUser = data.data.developmentUser
              //   this.dataForm.okrState = data.data.okrState
              //   this.dataForm.reciveDate = data.data.reciveDate
              //   this.dataForm.resolveDate = data.data.resolveDate
              //   this.dataForm.execDate = data.data.execDate
              //   var string = data.data.areaCode
              //   this.checkedCities = string.split(',') //字符串按逗号分隔成数组
            }
          }).catch((err) => {
            this.$message.error(err)
          })
        }
      })
    },

    handleCheckAllChange(options, value) {
      console.log(JSON.stringify(options))
      this.dataForm['months'] = value ? options.map(p => p['value' || 'value']) || [] : [];
      this.checkOptions['org'].isIndeterminate = false
    },
    // 下拉框值改变触发
    doSelectChange(options) {
      const checkLength = this.dataForm['months'].length
      this.checkOptions['org'].isIndeterminate = checkLength > 0 && checkLength < options.length;
      this.checkOptions['org'].checkAll = checkLength === options.length
    },


    //下属复选框
    // handleCheckAllChange(val) {
    //   this.checkedCities = val ? cityOptions : [];
    //   this.isIndeterminate = false;
    // },
    // handleCheckedCitiesChange(value) {
    //   let checkedCount = value.length;
    //   this.checkAll = checkedCount === this.cities.length;
    //   this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    // },
    //获取下级区域
    childCodeName(code) {
      if (cityOptions.length === 0) {
        childCode(code).then(({ data }) => {
          for (var i = 0; i <= data.data.length - 1; i++) {
            cityOptions.push(data.data[i].name);
          }
        })
      }
    },
    //获取月份列表
    selectMonthList() {
      this.$http({
        url: this.$http.adornUrl(`/dataflow/olxpokrmanagedetail/getMonth`),
        method: "get",
        params: this.$http.adornParams({
          'id': this.dataForm.id
        })
      }).then(({ data }) => {
        this.monthList = data.data
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        console.log(this.dataForm.months)
        if (valid) {
          this.isOnSubmit = true
          const data_ = {
            'mainId': this.dataForm.id,
            'resolveUser': this.userId,
            'addUserNumber': this.dataForm.addUserNumber,
            'months': this.dataForm.months,
            'valueAddedIncome': this.dataForm.valueAddedIncome,
            'publicBusinessIncome': this.dataForm.publicBusinessIncome,
            'developmentUser': this.dataForm.developmentUser,
            'okrYear': this.dataForm.okrYear,
            'areaCode': this.dataForm.areaCode,
            'orkName': this.dataForm.pName,
            'userId': this.userId,
            'userName': this.dataForm.userName
          }
          saveOrUpdate(data_).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    },
  }
}
</script>
  